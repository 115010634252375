import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { getPrograms, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import Seo from '../../components/Seo';
import { MAX_NUMBER_OF_POSTS_PER_PAGE, HT_DEFAULT_PAGE_NAME, HT_PAGE_CATEGORY_ARCHIVE_PAGE } from '../../constants';
import CreditCardsPointsPrograms from '../../components/EarnAndUsePoints/CreditCardsPointsPrograms';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface ICreditCardsPointsProgramsTemplateData {
  posts: Queries.WpPostConnection;
}

const CreditCardsPointsProgramsTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<ICreditCardsPointsProgramsTemplateData>) => {
  const {
    posts: { nodes },
  } = data;
  const currentPageIndex = (pageContext as any)?.pageNumber - 1 || 0;
  let posts: Queries.WpPost[] = [];

  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }
  const title = 'Credit Cards Points Programs';
  const programs = getPrograms(posts);

  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;
  return (
    <HTPageWrapper
      category={"Earn & Use Points, Credit Cards, " + HT_PAGE_CATEGORY_ARCHIVE_PAGE}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <CreditCardsPointsPrograms
        pageHeader={{
          title: title,
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
            {
              link: '/earn-use-points/',
              text: 'Earn & Use Points',
            },
            {
              link: '/earn-use-points/credit-cards/',
              text: 'Credit Cards Points Programs',
            },
          ],
          subTitle:
            'Learn the different ways to earn and redeem points from your credit card points program, with detailed guides from our industry experts.',
        }}
        programs={{
          programs,
        }}
        pagination={{
          currentPageIndex,
          pageSize: MAX_NUMBER_OF_POSTS_PER_PAGE,
          basePath: '/earn-use-points/credit-cards',
          numberOfPages: totalNumberOfPages,
        }}
      />
    </HTPageWrapper>
  );
};

export default CreditCardsPointsProgramsTemplate;

export const Head = ({ location, data }: PageProps<ICreditCardsPointsProgramsTemplateData>) => {
  const pageTitle = 'Credit Card Points Programs | UpgradedPoints.com';
  const socialTitle = pageTitle;
  const ogDescription =
    'Explore our credit card points program guides to get the most from your credit card. Our experts help you to choose the right credit card and earn points like a pro.';
  const includeCollectionPageType = true;
  let pathname = location.pathname;
  if (!pathname.endsWith('/')) pathname += '/';
  const pageNumber = pathname.includes('page') ? parseInt(pathname.slice(-2, -1)) : 1;
  const extraBreadcrumbList = [
    { name: 'Earn & Use Points', slug: 'earn-use-points' },
    { name: 'Credit Cards Points Programs', slug: 'credit-cards' },
  ];
  if (pageNumber > 1) {
    extraBreadcrumbList.push({
      name: 'Credit Cards Points Programs - Page ' + pageNumber,
      slug: 'page/' + pageNumber,
    });
  }
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  const firstPost = data?.posts?.nodes[0];
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      pageNumber={pageNumber}
    />
  );
};

export const query = graphql`
  query CreditCardsPointsPrograms($start: Int, $tagName: String) {
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { name: { regex: $tagName } } } } }
      limit: 21
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;
