import React, { FC } from 'react';
import { ICreditCardsPointsPrograms } from './CreditCardsPointsPrograms.def';
import PageHeader from '../../PageHeader/PageHeader';
import PaginationV2 from '../../PaginationV2/PaginationV2';
import Programs from '../../Programs/Programs';

const CreditCardsPointsPrograms: FC<ICreditCardsPointsPrograms> = ({
  pageHeader,
  pagination,
  programs,
}) => {
  return (
    <div className="credit-cards-points-programs">
      <PageHeader {...pageHeader} />
      <div className="container">
        <Programs {...programs} />
        <PaginationV2 {...pagination} />
      </div>
    </div>
  );
};

export default CreditCardsPointsPrograms;
